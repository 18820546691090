import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Typography from "../components/Typography/Typography";
import MainLayout from "../components/mainlayout";
import { mobileViewBreakpoint } from "../components/helper";
import Text from "../components/Text/Text";
import Summary from "../components/CaseStudy/Summary/Summary";

const useStyles = makeStyles(theme => ({
  caseStudyContainer: {
    boxSizing: "border-box",
    padding: "0px 32px",
    maxWidth: "1234px",
    margin: "80px auto 80px auto",
    "& > h2": {
      marginBottom: "28px",
      color: "#122044",
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        marginBottom: "24px",
        padding: "0px 16px"
      }
    },
    "& > h5": {
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        marginBottom: "24px"
      }
    },

    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      padding: "0px 0px 40px 0px",
      margin: "80px auto 0px auto"
    }
  },
  root: {
    margin: "0px -16px"
  }
}));
const Article = () => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query {
      bannerImage: file(
        relativePath: { eq: "logistics/EVENT LOGISTICS HEADER.jpg" }
      ) {
        childImageSharp {
          fixed(width: 800, height: 800) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <MainLayout
      title={"Event Logistics"}
      h1
      banner={
        <StaticImage
          src="../assets/images/logistics/EVENT LOGISTICS HEADER.jpg"
          draggable="false"
        />
      }
      breadcrumbs={[{ label: "Logistics" }]}
      metaTitle={"Event Planning and Logistics Service"}
      metaDescription={
        "Logistics required for an event? MOTUS | ONE is one of the best in event planning & logistics for a large event; we cover venue within the UAE, GCC & more."
      }
      shareImage={data.bannerImage.childImageSharp.fixed.src}
    >
      <div className={classes.root}>
        <div className={classes.caseStudyContainer}>
          <Typography component={"h2"} customVariant={"h2Bold"}>
            Consult with the best minds in logistics
          </Typography>
          <Text
            isFlipped={true}
            /* 
          subheader="How can we help?"
          header={
            <>
              Contact us to find out how events transport can fulfill your
              needs.{" "}
            </>
          }
          button={
            <a
              href="https://app.motusone.com/login"
              target="_blank"
              rel="noopener"
            >
              <Button className={classes.loginButton}>Login</Button>
            </a>
          }*/
            text={
              <Typography component="span" customVariant={"h5Regular"}>
                <p>
                  Our teams simplify the most complex movements of people, plant
                  materials. We can partner strategically to plan entire
                  systems, then deploy field experts to ensure they’re
                  implemented and managed effectively.
                </p>

                <p>
                  On the ground, our logistics managers work alongside engineers
                  and plant specialists to coordinate movements for maximum
                  safety and efficiency. Our goal is to tackle complexity and
                  mitigate risk in an environment that emphasizes best
                  practices, early reporting, and personal responsibility.
                </p>
              </Typography>
            }
          />
        </div>

        <Summary
          title={"End-to-end logistics support that drives project efficiency"}
          fullHeight={false}
          isFlipped={true}
          image={
            <StaticImage
              src="../assets/images/logistics/Logistics_1-1024x683.jpg"
              draggable="false"
            />
          }
          customWidth={640}
          customHeight={880}
          link={undefined}
          description={
            <>
              <p>
                Our logistics managers are skilled in managing complex team
                structures, enabling sub-contractors and project leads to
                coordinate closely. With tight liaison and quick reporting,
                teams can work with greater efficiency and expedience, which in
                turn delivers projects to higher standards of safety,
                reliability, and cost control.
              </p>
              <p>
                On-site, we ensure the utmost efficiency through our expertise
                in:
                <ul>
                  <li>Master Delivery Schedules</li>
                  <li>Vehicle Loading, Unloading, Staging protocol</li>
                  <li>Traffic and Parking Managment </li>
                </ul>
              </p>

              <p>
                At the project scale, we work effectively to deliver:
                <ul>
                  <li>Traffic, Transport, and Parking Master Planning</li>
                  <li>Complex Fleet and Passenger Scheduling </li>
                  <li>Staff onboarding, training, and welfare</li>
                </ul>
              </p>

              <p>
                By coordinating these activities and more, we can help your
                delivery team to mitigate risk and deliver projects on time and
                within budget.
              </p>
            </>
          }
        />
      </div>
    </MainLayout>
  );
};

export default Article;
