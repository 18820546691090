import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "../../components/Typography/Typography";
import Button from "../../components/Button/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Container from "@material-ui/core/Container";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { mobileViewBreakpoint } from "../helper";
import { Link } from "gatsby";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginBottom: "160px",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      marginBottom: "128px"
    }
  },
  textHeader: ({ halfHeight }) => ({
    position: "absolute",
    bottom: 40,
    [theme.breakpoints.down("md")]: {
      bottom: 16,
      width: "92%"
    }
  }),
  bgHeader: {
    position: "relative",
    height: "600px",

    "&::after": {
      content: '""',
      position: "absolute",
      display: "block",
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0,0,0,0.2)",
      top: "0px",
      left: "0px"
    },
    [theme.breakpoints.down("md")]: {
      height: "361px",
      marginBottom: "0px"
    }
  },
  headerContainer: {
    position: "relative",
    zIndex: 99,
    height: "100%",
    maxWidth: 1234,
    paddingLeft: 32,
    paddingRight: 32,
    [theme.breakpoints.down("md")]: {
      padding: "24px 16px"
    }
  },
  headerImage: {
    position: "absolute",
    width: "100%",
    overflowX: "hidden",
    overflowY: "hidden",
    height: "100%",
    "& .gatsby-image-wrapper": {
      width: "100%",
      height: "100%"
    },
    [theme.breakpoints.down("md")]: {
      height: "361px",
      "& > .gatsby-image-wrapper": {
        height: "361px"
      }
    }
  },
  subTitleSection: {
    marginBottom: "24",
    marginTop: 24,

    "& h4, p": {
      color: "#fff",
      marginBottom: 24,
      maxWidth: 578,

      [theme.breakpoints.down("md")]: {
        marginBottom: 16
      }
    },

    "& a": {
      color: "#fff"
    },

    [theme.breakpoints.down("md")]: {
      marginBottom: 16,
      marginTop: 12
    }
  }
}));

export default function ButtonAppBar({
  halfHeight,
  image,
  children,
  breadcrumbs,
  blur
}) {
  const classes = useStyles({ halfHeight });
  const theme = useTheme();
  const isMobileView = useMediaQuery(
    theme.breakpoints.down(mobileViewBreakpoint)
  );

  return (
    <div className={(classes.root, classes.bgHeader)}>
      <div className={classes.headerImage}>{image}</div>
      <Container
        width="lg"
        maxWidth={false}
        className={classes.headerContainer}
      >
        <div className={classes.textHeader}>
          {children}

          <div className={classes.subTitleSection}>
            <Typography
              customVariant={isMobileView ? "bodySmallRegular" : "h4Regular"}
            >
              <a href="https://motusone.com/">Home</a> /{" "}
              {breadcrumbs &&
                breadcrumbs.map((x, index) => (
                  <>
                    {x.link && <a href={x.link}>{x.label}</a>}
                    {!x.link && <span>{x.label}</span>}{" "}
                    {index < breadcrumbs.length - 1 ? "/ " : ""}{" "}
                  </>
                ))}
            </Typography>
          </div>
        </div>
      </Container>
    </div>
  );
}
