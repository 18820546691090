import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "../../components/Typography/Typography";
import Button from "../../components/Button/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { mobileViewBreakpoint } from "../helper";

const useStyles = makeStyles(theme => ({
  root: ({ largeWidth }) => ({
    maxWidth: largeWidth ? 944 : 763
  }),
  heading: ({ center }) => ({
    color: "#CC4A01",
    marginBottom: 16,
    textAlign: center ? "center" : undefined,
    fontWeight: "bold",
    fontSize: "20px"
  }),
  subTitleText: ({ subtitle, buttonLabel, dark, center }) => ({
    marginBottom: subtitle || buttonLabel ? 16 : 0,
    color: dark ? "#fff" : "#122044",
    textAlign: center ? "center" : undefined,
    fontWeight: "bold"
  }),

  titleTextMd: ({ subtitle }) => ({
    fontSize: !subtitle ? "60px" : "40px",
    lineHeight: !subtitle ? "70px" : "50px",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      fontSize: !subtitle ? "30px" : "20px",
      lineHeight: !subtitle ? "40px" : "30px",
      padding: !subtitle ? "auto" : "16px"
    }
  })
}));

export default function ButtonAppBar({
  title,
  subheading,
  subtitle,
  buttonLabel,
  dark,
  center,
  largeWidth,
  h1
}) {
  const classes = useStyles({
    largeWidth,
    center,
    subtitle,
    buttonLabel,
    dark
  });
  const theme = useTheme();
  const isMobileView = useMediaQuery(
    theme.breakpoints.down(mobileViewBreakpoint)
  );

  return (
    <div
      className={classes.root}
      style={{
        maxWidth: center && "100%"
      }}
    >
      {subheading && (
        <Typography
          customVariant={"h5Bold"}
          component={"div"}
          className={classes.heading}
        >
          {subheading}
        </Typography>
      )}
      {(isMobileView && (
        <Typography
          customVariant={!subtitle ? "h3Bold" : "h5Bold"}
          className={`${classes.subTitleText} ${classes.titleTextMd}`}
          component={h1 ? "h1" : "h2"}
        >
          {title}
        </Typography>
      )) || (
        <Typography
          className={`${classes.subTitleText} ${classes.titleTextMd}`}
          customVariant={!subtitle ? "h1Bold" : "h2Bold"}
          component={h1 ? "h1" : "h2"}
        >
          {title}
        </Typography>
      )}

      {subtitle && (
        <Typography
          customVariant={"h5Regular"}
          style={{ marginBottom: 24, maxWidth: 578 }}
          component="span"
        >
          {subtitle}
        </Typography>
      )}
      {buttonLabel && <Button color="inherit">{buttonLabel}</Button>}
    </div>
  );
}
