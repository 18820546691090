import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "../../Typography/Typography";
import Button from "../../Button/Button";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { mobileViewBreakpoint } from "../../helper.js";
import { Link } from "gatsby";
const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      marginTop: "0px"
    }
  },
  caseStudyDesignOne: {
    display: "flex",
    maxWidth: "1234px",
    boxSizing: "border-box",
    padding: "0px 32px",
    margin: "80px auto 80px auto",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      flexDirection: "column",
      margin: "0 auto 72px auto",
      padding: "0px"
    }
  },
  caseStudyDesignTwo: {
    display: "flex",
    maxWidth: "1234px",
    boxSizing: "border-box",
    padding: "0px 32px",
    flexDirection: "row-reverse",
    margin: "80px auto 80px auto",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      flexDirection: "column",
      margin: "0 auto 72px auto",
      padding: "0px"
    }
  },

  rowReverse: {
    flexDirection: "row-reverse",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      flexDirection: "column"
    }
  },
  caseStudyImage: ({ customWidth, customHeight }) => ({
    "& .gatsby-image-wrapper": {
      width: customWidth ? customWidth : 740,
      height: customHeight ? customHeight : 630,
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        width: "100%",
        height: "100%"
      }
    },
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      height: 200,
      objectFit: "cover",
      overflow: "hidden",
      marginBottom: 16
    }
  }),
  caseStudyImageFull: ({ customWidth, customHeight }) => ({
    "& .gatsby-image-wrapper": {
      width: customWidth ? customWidth : 740,
      height: customHeight ? customHeight : 630,
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        width: "100%",
        height: "100%"
      }
    },
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      height: "auto",
      objectFit: "cover",
      overflow: "hidden"
    }
  }),
  contentTypeRight: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    padding: "0px 0px 0px 80px",

    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      padding: "0px 16px"
    },
    "& a": {
      "&:hover": {
        textDecoration: "none"
      }
    }
  },

  contentTypeLeft: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    padding: "0px 80px 0px 0px",

    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      padding: "0px 16px"
    },
    "& a": {
      "&:hover": {
        textDecoration: "none"
      }
    }
  },
  summaryType: {
    color: "#CC4A01",
    fontWeight: "bold",
    margin: "28px 0px 16px 0px",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      margin: "16px 0px 8px 0px"
    }
  },
  summaryTitle: {
    color: "#122044",
    fontSize: "40px",
    lineHeight: "48px",
    letterSpacing: "-0.4px",
    marginBottom: "24px",
    fontWeight: "bold",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      fontSize: "30px",
      lineHeight: "37px",
      marginBottom: "16px"
    }
  },
  summaryDate: {
    color: "rgba(0,0,0,0.5)",
    margin: "24px 0px",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      margin: "8px 0px"
    }
  },
  summaryDesc: {
    color: "rgba(0,0,0,0.6)",
    marginBottom: "32px",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      margin: "0px 0px 24px 0px"
    }
  }
}));

const Summary = props => {
  const { customWidth, customHeight } = props;
  const classes = useStyles({ customWidth, customHeight });
  const theme = useTheme();
  const isMobileView = useMediaQuery(
    theme.breakpoints.down(mobileViewBreakpoint)
  );
  return (
    <div className={classes.root}>
      <div
        className={
          props.isFlipped
            ? `${classes.caseStudyDesignTwo} ${classes.rowReverse}`
            : classes.caseStudyDesignOne
        }
      >
        <div
          className={
            props.fullHeight
              ? `${classes.caseStudyImageFull}`
              : `${classes.caseStudyImage}`
          }
        >
          {props.image}
        </div>

        <div
          className={
            props.isFlipped
              ? `${classes.contentTypeLeft}`
              : `${classes.contentTypeRight}`
          }
        >
          <Typography
            customVariant={"h5Regular"}
            className={classes.summaryType}
            component={"span"}
          >
            {props.category}
          </Typography>
          <a href={props.link}>
            <Typography
              customVariant={isMobileView ? "h4Bold" : "h3Bold"}
              component="h2"
              className={classes.summaryTitle}
            >
              {props.title}
            </Typography>
          </a>

          <Typography
            customVariant={"h5Regular"}
            className={classes.summaryDesc}
            component="span"
          >
            {props.description}
          </Typography>

          {props.link && (
            <a href={props.link}>
              <Button Button>
                {props.buttonText ? props.buttonText : "Read More"}
              </Button>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default Summary;
