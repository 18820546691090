import React from "react";
import PropTypes from "prop-types";
import MainMenu from "./MainMenu/MainMenu";
import Footer from "./Footer/Footer";
import CopyRights from "./Footer/CopyRights/CopyRights";
import Header from "./Header/Header";
import HeadingCTA from "./HeadingCTA/HeadingCTA";
import Container from "@material-ui/core/Container";
import SEO from "./seo";
import "../assets/css/main.css";

const Layout = ({
  children,
  title,
  metaTitle,
  metaDescription,
  shareImage,
  banner,
  breadcrumbs,
  h1
}) => {
  return (
    <>
      <MainMenu />
      <SEO
        seo={{
          metaTitle,
          metaDescription,
          shareImage
        }}
      />
      <Header image={banner} halfHeight breadcrumbs={breadcrumbs}>
        <HeadingCTA dark title={title} h1={h1} />
      </Header>

      <Container maxWidth={false}>{children}</Container>

      <Footer />
      <CopyRights />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
