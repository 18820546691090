import React from "react";
import Typography from "../../components/Typography/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { mobileViewBreakpoint } from "../helper";
const useStyles = makeStyles(theme => ({
  root: ({ isFlipped }) => ({
    display: "flex",
    flexDirection: isFlipped ? "row-reverse" : "row",
    maxWidth: 1234,
    margin: "40px auto",
    alignItems: "top",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      flexDirection: "column",
      padding: "20px",
      margin: "8px auto"
    }
  }),
  title: {
    color: "#122044",
    fontSize: "40px",
    lineHeight: "48px",
    fontWeight: "bold",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      fontSize: "30px",
      lineHeight: "37px",
      marginBottom: "8px"
    }
  },
  subTitle: {
    color: "#CC4A01",
    fontWeight: "bold",
    margin: "0px 0px 16px 0px",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      margin: "16px 0px 8px 0px"
    }
  },
  contentRight: ({ isFlipped }) => ({
    padding: isFlipped ? "0px 100px 0px 0px" : "0px 0px 0px 100px",
    flex: 0.65,
    "& p": {
      fontSize: "20px",
      color: "rgba(0,0,0,0.6)",
      lineHeight: "30px"
    },
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      padding: "0px"
    }
  }),
  contentLeft: {
    flex: 0.35
  }
}));
const Text = ({ isFlipped, subheader, header, text }) => {
  const classes = useStyles({ isFlipped });
  const theme = useTheme();
  const isMobileView = useMediaQuery(
    theme.breakpoints.down(mobileViewBreakpoint)
  );
  return (
    <div className={classes.root}>
      <div className={classes.contentLeft}>
        {subheader && (
          <Typography customVariant={"h5Regular"} className={classes.subTitle}>
            {subheader}
          </Typography>
        )}
        <Typography
          customVariant={isMobileView ? "h4Bold" : "h3Bold"}
          className={classes.title}
        >
          {header}
        </Typography>
      </div>
      <div className={classes.contentRight}>
        <Typography customVariant={"bodyLargeRegular"}>{text}</Typography>
      </div>
    </div>
  );
};

export default Text;
